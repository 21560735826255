import React from "react";

const PersonSection = ({ header, content, imageUrl, media }) => (
    <section className="person-section">
        <div>
            <h1>{header} &#123;</h1>
            <p>{content}</p>

            <div className="media">
                {media.map(m => (
                    <a href={m.url} key={m.url} target="_blank">
                        {m.icon}
                    </a>
                ))}
            </div>

            <h1>&#125;</h1>
        </div>

        <img src={imageUrl} />
    </section>
);

export default PersonSection;
