import React from "react";
import HeaderSection from "./components/HeaderSection";
import PersonSection from "./components/PersonSection";
import FooterSection from "./components/FooterSection";
import ScrollSlideIn from "./components/ScrollSlideIn";
import tymekImg from "./images/tymek.png";
import jakubImg from "./images/jakub.png";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { FaItchIo } from "react-icons/fa";

// TODO przeskaluj obrazki

const personsInfo = [
    {
        header: "Jakub",
        content: (
            <>
                Rozwój technologi to moja <span className="accent">pasja</span>
                <br />
                Studiuję IST na PWr
                <br />
                Pracuję jako programista
                <br />
                Pragnę zbudować startup
            </>
        ),
        imageUrl: jakubImg,
        media: [
            {
                url: "https://github.com/stepkos",
                icon: <AiFillGithub />,
            },
            {
                url: "https://www.linkedin.com/in/jakub-stepkowski/",
                icon: <AiFillLinkedin />,
            },
        ],
    },
    {
        header: "Tymoteusz",
        content: (
            <>
                Poznaję i <span className="accent">kreuję</span> otaczający mnie
                świat
                <br />
                Jestem uczniem ULO w Toruniu
                <br />
                Kocham zdobywać wiedzę i<br />
                Wprowadzać w życie własne pomysły
            </>
        ),
        imageUrl: tymekImg,
        media: [
            {
                url: "https://github.com/tymowskyy",
                icon: <AiFillGithub />,
            },
            {
                url: "https://tymowskyy.itch.io/",
                icon: <FaItchIo />,
            },
        ],
    },
];

function App() {
    if (Math.random() > 0.5) personsInfo.reverse();

    return (
        <>
            <ScrollSlideIn defaultActive="defaultActive">
                <HeaderSection />
            </ScrollSlideIn>

            {personsInfo.map(person => (
                <ScrollSlideIn key={person.header}>
                    <PersonSection key={person.header} {...person} />
                </ScrollSlideIn>
            ))}

            <ScrollSlideIn>
                <FooterSection />
            </ScrollSlideIn>
        </>
    );
}

export default App;
