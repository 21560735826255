import React, { useState, useRef, useEffect } from "react";

const ScrollSlideIn = ({ children, defaultActive }) => {
    const [isIntersecting, setIsIntersecting] = useState(
        defaultActive || false
    );
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: "-150px" }
        );
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [isIntersecting]);

    useEffect(() => {
        if (isIntersecting) ref.current.classList.add("slide-in");
        else ref.current.classList.remove("slide-in");
    }, [isIntersecting]);

    return (
        <div className="slide-in-container" ref={ref}>
            {children}
        </div>
    );
};

export default ScrollSlideIn;
