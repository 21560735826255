import React from "react";

const FooterSection = () => (
    <section className="footer-section">
        <h1>&#125;</h1>

        <div className="comments">
            <h3>
                // All rights reserved &copy; 2023
                <br />
                // Tymoteusz Stępkowski, Jakub Stępkowski
            </h3>
        </div>
    </section>
);

export default FooterSection;
