import React from "react";

const HeaderSection = () => (
    <section className="header-section">
        <h1>Stępkowscy &#123;</h1>

        <div className="comments">
            <h3>
                // Dwaj bracia z pasją
                <br />
                // i wielkimi marzeniami
            </h3>
        </div>
    </section>
);

export default HeaderSection;
